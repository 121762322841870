import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class EnvCommonService {

  private tokenKey = 'orcoses'; 

  constructor(private cookieService: CookieService) {}

  getToken(): string {
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      
      //return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFuZ2VsMDdAZ21haWwuY29tIiwidWlkIjoyNSwicmlkIjoyNSwiYXBpX2tleSI6IkE0Q0k5ODFGSUgxZElHTGtCOWkwSDRFRmNtY2UzbGgyIiwiZm5hbWUiOiJBbmdlbCIsImNvdW50cnkiOiIwMSIsInN0YXRlIjoidGFtaWxuYWR1IiwiaWF0IjoxNzMzMjI3OTAxLCJleHAiOjE3MzMyMzE1MDF9.PlNByDOHiH57u-TVAWWQNzLklW7eMBVbSor79w_Eivk';
      
      return this.cookieService.get(this.tokenKey);
    }
    const cookieValue = this.cookieService.get(this.tokenKey);
    if (cookieValue) {
      return cookieValue;
    }
    return 'Invalid Token Found!';
  }

  getDname(): string {
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      return 'list.orcollate.xyz';
    } else {
      return window.location.hostname;
    }
  }
  getshopDname(): string {
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      return 'shop.orcollate.xyz';
    } else {
      const currentUrl = window.location.hostname;
      const updatedUrl = currentUrl.replace('list', 'shop');
      return updatedUrl;
    }
  }
}
