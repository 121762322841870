<style>
  .order-details {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 40px 60px;
    border-radius: 10px;
    width: 100%;
    margin: auto;
    margin: 20px auto;
    line-height: 40px;
    border: 1px solid #000;
  }

  .order-row {
    display: flex;
    margin-bottom: 10px;
  }

  .order-label {
    font-weight: bold;
    color: #000;
  }

  .order-value {
    margin-left: 10px;
  }
</style>
<div class="auth-container">
  <app-home-header></app-home-header>
  <div class="bg1">
    <div class="container c2">
      <div class="vh-100 d-flex justify-content-center align-items-center">
        <div>
          <div class="mb-4 text-center" style="color: #8bc34a">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="text-success"
              width="75"
              height="75"
              fill="currentColor"
              class="bi bi-check-circle-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
              />
            </svg>
          </div>
          <div class="text-center">
            <h1>
              <span *ngIf="this.ulevel == 1"
                >Congratulations! Your Payment Was Successful!</span
              ><span *ngIf="this.ulevel == 3"
                >Congratulations! Your Request is Successful!</span
              >
            </h1>
            <div class="order-details">
              <div class="order-row">
                <div class="order-label">Order Number:</div>
                <div class="order-value">{{ finvno2 }}</div>
              </div>

              <div class="order-row">
                <div class="order-label">Transaction ID:</div>
                <div class="order-value">{{ payid }}</div>
              </div>

              <div class="order-row">
                <div class="order-label">Total Amount:</div>

                <div class="order-value">{{ ftotamt2 | currency : ctype }}</div>
              </div>
            </div>
            <a class="btn btn-primary" [href]="environment.homeurl"
              >Back to Home</a
            >

            <p *ngIf="this.ulevel == 3" style="margin-top: 20px">
              <b>Note: </b> The above amount is indicative amount. Actual bill
              will sent based on your T&C.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Busistream-->
</div>
<app-home-footer></app-home-footer>
